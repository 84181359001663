import type React from 'react';
import { useNavigate } from 'react-router-dom'; // 추가
import type { ClassroomListProps, Classroom } from 'features/Classroom/ClassroomInterfaces';
import { getClassroom } from 'features/Classroom/ClassroomService';
import { useAuth } from 'features/Auth/contexts/AuthContext'; // Auth 컨텍스트 import

// 타입 가드 함수
const isMessageObject = (
  classrooms: Classroom[] | { message: string }
): classrooms is { message: string } => {
  return 'message' in classrooms;
};

const ClassroomList: React.FC<ClassroomListProps> = ({
  classrooms,
  user,
  onJoinClassroom,
  onViewClassroom,
}) => {
  const { user: authUser } = useAuth(); // Auth 컨텍스트에서 user 가져오기
  const navigate = useNavigate(); // 추가

  const handleViewClassroom = async (classroomId: string) => {
    try {
      if (authUser) {
        const classroomDetail = await getClassroom(classroomId, authUser.token);
        console.log('Classroom detail:', classroomDetail);
        navigate(`/classrooms/${classroomId}`, { state: { classroomDetail } });
      } else {
        console.error('User is not authenticated');
      }
    } catch (error) {
      console.error('Error fetching classroom details:', error);
    }
  };

  if (isMessageObject(classrooms)) {
    return <div>{classrooms.message}</div>;
  }

  if (classrooms.length === 0) {
    return <div>No classrooms available.</div>;
  }

  return (
    <div>
      <h2>Classrooms</h2>
      <ul>
        {classrooms.map((classroom) => (
          <li key={classroom.id}>
            {classroom.name}: {classroom.description}
            {user?.role === 'student' ? (
              <button type="button" onClick={() => onJoinClassroom(classroom.id)}>
                Join
              </button>
            ) : user?.role === 'teacher' && classroom.teacher_id === user.id ? (
              <button type="button" onClick={() => handleViewClassroom(classroom.id)}>
                View
              </button>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClassroomList;

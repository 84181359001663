import type React from 'react';
import { useEffect } from 'react';
import ActivityList from '../components/ActivityList';
import { useActivity } from '../contexts/ActivityContext';

interface ActivityListContainerProps {
  classroomId: string;
}

const ActivityListContainer: React.FC<ActivityListContainerProps> = ({ classroomId }) => {
  const { activities, loading, error, setClassroomId, addActivity, removeActivity } = useActivity();

  console.log('ActivityListContainer rendered with classroomId:', classroomId); // 로그 유지

  useEffect(() => {
    setClassroomId(classroomId);
  }, [classroomId, setClassroomId]);

  if (loading) return <div>Loading activities...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <ActivityList
      activities={activities}
      onCreateActivity={addActivity}
      onDeleteActivity={removeActivity}
      classroomId={classroomId} // 이 부분 확인
    />
  );
};

export default ActivityListContainer;

import type React from 'react';
import { useState, useEffect } from 'react';
import type { ActivityFormProps, ActivityCreate } from '../ActivityInterfaces';

const ActivityForm: React.FC<ActivityFormProps> = ({ onSubmit, classroomId }) => {
  console.log('ActivityForm rendering with classroomId:', classroomId); // 로그 유지

  const [activityData, setActivityData] = useState<ActivityCreate>(() => ({
    name: '',
    description: '',
    classroom_id: classroomId || '',
  }));

  useEffect(() => {
    console.log('ActivityForm useEffect triggered with classroomId:', classroomId); // 로그 추가
    if (classroomId) {
      setActivityData(prevData => ({
        ...prevData,
        classroom_id: classroomId
      }));
    }
  }, [classroomId]);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setActivityData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submitting activity with data:', activityData); // 제출 시 로그 추가
    onSubmit(activityData);
    setActivityData({ name: '', description: '', classroom_id: classroomId || '' });
    setIsFormVisible(false);
  };

  return (
    <div>
      {!isFormVisible ? (
        <button type="button" onClick={() => setIsFormVisible(true)}>Create New Activity</button>
      ) : (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            value={activityData.name}
            onChange={handleChange}
            placeholder="Activity Name"
            required
          />
          <textarea
            name="description"
            value={activityData.description}
            onChange={handleChange}
            placeholder="Activity Description"
            required
          />
          <button type="submit">Create Activity</button>
          <button type="button" onClick={() => setIsFormVisible(false)}>Cancel</button>
        </form>
      )}
    </div>
  );
};

export default ActivityForm;
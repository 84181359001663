import type React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import type { ClassroomDetail } from 'features/Classroom/ClassroomInterfaces';
import ActivityListContainer from 'features/Activity/containers/ActivityListContainer';
import StudentList from '../components/StudentList';
import PendingStudentList from '../components/PendingStudentList';
import { getClassroom } from 'features/Classroom/ClassroomService';
import { useAuth } from 'features/Auth/contexts/AuthContext';
import { ActivityProvider } from 'features/Activity/contexts/ActivityContext';

const ClassroomMainContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [classroom, setClassroom] = useState<ClassroomDetail | null>(
    location.state?.classroomDetail || null
  );
  const [loading, setLoading] = useState<boolean>(!location.state?.classroomDetail);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!classroom && id && user?.token) {
        try {
          const data = await getClassroom(id, user.token);
          setClassroom(data);
        } catch (err) {
          setError('Failed to fetch classroom data');
        } finally {
          setLoading(false);
        }
      } else if (!classroom) {
        console.error('Invalid classroom ID or missing user token');
        navigate('/');
      }
    };

    fetchData();
  }, [id, user, navigate, classroom]);

  console.log('ClassroomMainContainer rendered with id:', id); // 로그 추가

  console.log('ClassroomMainContainer rendering with classroom id:', classroom?.id); // 로그 추가

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!classroom) {
    return <div>No classroom data found</div>;
  }

  return (
    <div>
      <h2>{classroom.name}</h2>
      <p>{classroom.description}</p>
      <ActivityProvider>
        <ActivityListContainer classroomId={classroom?.id || ''} />
      </ActivityProvider>
      <StudentList students={classroom.student_details} />
      <PendingStudentList pendingStudents={classroom.pending_student_details} />
    </div>
  );
};

export default ClassroomMainContainer;

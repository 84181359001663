import type { Activity, ActivityCreate } from './ActivityInterfaces';
import fastapi from 'features/Common/utils/fastapi';

export const createActivity = async (
  activityData: ActivityCreate,
  token: string
): Promise<Activity> => {
  console.log('createActivity called with:', activityData, token); // 로그 추가
  return new Promise((resolve, reject) => {
    fastapi(
      'post',
      '/api/activity',
      activityData,
      token,
      (data: Activity) => {
        console.log('Activity created successfully:', data); // 로그 추가
        resolve(data);
      },
      (error: Error) => {
        console.error('Error in createActivity:', error); // 에러 로그
        reject(error);
      }
    );
  });
};

export const getActivities = async (
  classroomId: string,
  token: string
): Promise<Activity[]> => {
  return new Promise((resolve, reject) => {
    fastapi(
      'get',
      `/api/activity?classroom_id=${classroomId}`,
      {},
      token,
      (data: Activity[]) => resolve(data),
      (error: Error) => reject(error)
    );
  });
};

export const deleteActivity = async (
  activityId: string,
  token: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    fastapi(
      'delete',
      `/api/activity/${activityId}`,
      {},
      token,
      () => resolve(),
      (error: Error) => reject(error)
    );
  });
};
import type React from 'react';
import { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import type { Activity, ActivityCreate } from '../ActivityInterfaces';
import { createActivity, getActivities, deleteActivity } from '../ActivityService';
import { useAuth } from 'features/Auth/contexts/AuthContext';

interface ActivityContextType {
  activities: Activity[];
  loading: boolean;
  error: string | null;
  classroomId: string | null;
  setClassroomId: (id: string) => void;
  fetchActivities: () => Promise<void>;
  addActivity: (activityData: ActivityCreate) => Promise<void>;
  removeActivity: (activityId: string) => Promise<void>;
}

const ActivityContext = createContext<ActivityContextType | undefined>(undefined);

export const useActivity = () => {
  const context = useContext(ActivityContext);
  if (!context) {
    throw new Error('useActivity must be used within an ActivityProvider');
  }
  return context;
};

export const ActivityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [classroomId, setClassroomId] = useState<string | null>(null);
  const { user } = useAuth();

  const fetchActivities = useCallback(async () => {
    if (!user?.token || !classroomId) return;
    setLoading(true);
    try {
      const fetchedActivities = await getActivities(classroomId, user.token);
      setActivities(fetchedActivities);
    } catch (err) {
      setError('활동을 가져오는 데 실패했습니다');
    } finally {
      setLoading(false);
    }
  }, [user, classroomId]);

  const addActivity = useCallback(async (activityData: ActivityCreate) => {
    if (!user?.token || !classroomId) return;
    try {
      const newActivity = await createActivity({ ...activityData, classroom_id: classroomId }, user.token);
      setActivities(prev => [...prev, newActivity]);
    } catch (err) {
      setError('활동을 생성하는 데 실패했습니다');
    }
  }, [user, classroomId]);

  const removeActivity = useCallback(async (activityId: string) => {
    if (!user?.token) return;
    try {
      await deleteActivity(activityId, user.token);
      setActivities(prev => prev.filter(activity => activity.id !== activityId));
    } catch (err) {
      setError('활동을 삭제하는 데 실패했습니다');
    }
  }, [user]);

  useEffect(() => {
    if (classroomId) {
      fetchActivities();
    }
  }, [classroomId, fetchActivities]);

  const contextValue: ActivityContextType = {
    activities,
    loading,
    error,
    classroomId,
    setClassroomId,
    fetchActivities,
    addActivity,
    removeActivity,
  };

  console.log('ActivityProvider rendered with classroomId:', classroomId); // 로그 추가

  return (
    <ActivityContext.Provider value={contextValue}>
      {children}
    </ActivityContext.Provider>
  );
};

import type React from 'react';
import type { ActivityListProps } from '../ActivityInterfaces';
import ActivityForm from './ActivityForm';

const ActivityList: React.FC<ActivityListProps> = ({ activities, onCreateActivity, onDeleteActivity, classroomId }) => {
  console.log('ActivityList rendering with classroomId:', classroomId); // 로그 추가

  return (
    <div>
      <h3>Activities</h3>
      <ActivityForm onSubmit={onCreateActivity} classroomId={classroomId} />
      <ul>
        {activities.map((activity) => (
          <li key={activity.id}>
            {activity.name} - {activity.description}
            <button type="button" onClick={() => onDeleteActivity(activity.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActivityList;